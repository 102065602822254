<template>
  <a-layout>
    <a-breadcrumb style="margin: 16px 0">
      <a-breadcrumb-item>{{ $route.name }}</a-breadcrumb-item>
    </a-breadcrumb>

    <a-layout-content class="content">
      <a-page-header
        class="demo-page-header"
        style=" padding: 0;"
        title="Salary"
      >
        <template #extra>
          <a-button key="1" type="primary" @click="getsalary(userInfo.id)"
            >My Salary</a-button
          >
        </template>
      </a-page-header>

      <a-table
        class="table"
        :loading="loading"
        :columns="columns"
        :data-source="list"
        :pagination="{
          hideOnSinglePage: true,
          total: total,
          current: currentPage,
          defaultPageSize: limit,
        }"
        @change="tableChange"
      >
        <template #name="{ text }">
          <a>{{ text }}</a>
        </template>
        <template #total="{ text }">
          <a>{{ Number(text / 100).toFixed(2) }}</a>
        </template>
        <template #daySalary="{ text }">
          <a>{{ Number(text / 100).toFixed(2) }}</a>
        </template>
        <template #EmployeePart="{ text }">
          <a>{{ text > 0 ? Number(text / 100).toFixed(2) : '' }}</a>
        </template>
        <template #leaveDeduct="{ text }">
          <a>{{ text > 0 ? Number(text / 100).toFixed(2) : '' }}</a>
        </template>
        <template #actualPayOff="{ text }">
          <a>{{ Number(text / 100).toFixed(2) }}</a>
        </template>
        <template #personalIncomeTax="{ text }">
          <a>{{ text > 0 ? Number(text / 100).toFixed(2) : '' }}</a>
        </template>
      </a-table>
    </a-layout-content>

    <!--  salary modal  -->
    <a-modal
      v-model:visible="showModal"
      title="Salary"
      :maskClosable="false"
      :keyboard="false"
      centered
    >
      <a-form layout="vertical" v-if="salary">
        <label class="" title="user">User</label>
        <a-typography-title :level="4">{{
          userInfo.username
        }}</a-typography-title>

        <a-form-item label="Total Before Tax 税前月薪">
          <a-input v-model:value.number="salary.total" readonly />
        </a-form-item>
        <a-form-item label="Social Security (Personal part) 社保个人部分">
          <a-input v-model:value.number="salary.socialSecurityEmployeePart" readonly/>
        </a-form-item>
        <a-form-item label="Social Security (Personal part) 社保公司部分">
          <a-input
            v-model:value.number="salary.socialSecurityCompanyPart"
            readonly
          />
        </a-form-item>
        <a-form-item label="Personal Income Tax 个人所得税">
          <a-input v-model:value.number="salary.personalIncomeTax" readonly />
        </a-form-item>
        <a-form-item label="Remarks 备注">
          <a-input v-model:value="salary.remarks" readonly />
        </a-form-item>
        <label class="" title="total">Total After Tax 税后月薪</label>
        <a-typography-title :level="4">{{ salaryresult }}</a-typography-title>
      </a-form>
      <a-empty v-else />
      <template #footer>
        <a-button key="submit" :loading="loading" @click="showModal = false"
          >Close</a-button
        >
      </template>
    </a-modal>

    <a-layout-footer class="footer">&copy;2021 Springup Inc.</a-layout-footer>
  </a-layout>
</template>

<script>
import {
  computed,
  getCurrentInstance,
  onMounted,
  onUnmounted,
  reactive,
  toRefs,
} from "vue";
import { useStore } from "vuex";
const columns = [
  {
    title: "月份",
    dataIndex: "month",
    ellipsis: true,
  },
  {
    title: "有效工作日(天)",
    dataIndex: "workdays",
    ellipsis: true,
  },
  {
    title: "实际工作日(天)",
    dataIndex: "actualWorkdays",
    ellipsis: true,
  },
  {
    title: "税前月薪(元)",
    dataIndex: "total",
    ellipsis: true,
    slots: { customRender: "total" },
  },
  {
    title: "税前日薪(元)",
    dataIndex: "daySalary",
    ellipsis: true,
    slots: { customRender: "daySalary" },
  },
  {
    title: "社保个人部分(元)",
    dataIndex: "socialSecurityEmployeePart",
    ellipsis: true,
    slots: { customRender: "EmployeePart" },
  },
  {
    title: "个人所得税(元)",
    dataIndex: "personalIncomeTax",
    ellipsis: true,
    slots: { customRender: "personalIncomeTax" },
  },
  {
    title: "请假概要",
    dataIndex: "leaveSummary",
    ellipsis: true,
    width: 200,
  },
  {
    title: "请假扣除(元)",
    dataIndex: "leaveDeduct",
    ellipsis: true,
    slots: { customRender: "leaveDeduct" },
  },
  {
    title: "税后实发(元)",
    dataIndex: "actualPayoff",
    ellipsis: true,
    slots: { customRender: "actualPayOff" },
  },
  {
    title: "薪水概要",
    dataIndex: "salarySummary",
    ellipsis: true,
    width: 300,
  },
];
export default {
  components: {},
  setup() {
    const { proxy } = getCurrentInstance();
    const store = useStore();
    const v = reactive({
      list: [],
      page: 1,
      limit: 10,
      total: 0,
      currentPage: 1,
      columns,

      siderStatus: false,
      loading: false,

      showModal: false,
      salary: null,
    });

    const userInfo = computed(() => store.state.userInfo);

    async function getlist(id) {
      v.loading = true;
      let res;
      try {
        res = await proxy.$axios.get(`/api/v1/users/${id}/salaries`, {
          page: v.page,
          limit: v.limit,
        });
      } catch (err) {}

      v.loading = false;
      if (res && res.data) {
        v.list = res.data.items;
        v.total = res.data.total;
        v.list.forEach((item, index) => {
          item.key = index;
        });
      }
    }
    getlist(userInfo.value.id);

    async function getsalary(id) {
      v.loading = true;
      const res = await proxy.$axios.get(`/api/v1/users/${id}/salary_settings/current`);
      v.loading = false;
      if (res.data) {
        let {
          total,
          socialSecurityCompanyPart,
          socialSecurityEmployeePart,
          personalIncomeTax,
          remarks,
        } = res.data;
        v.salary = {
          total: total / 100,
          socialSecurityCompanyPart: socialSecurityCompanyPart / 100,
          socialSecurityEmployeePart: socialSecurityEmployeePart / 100,
          personalIncomeTax: personalIncomeTax / 100,
          remarks,
        };
      }
      v.showModal = true;
    }

    const salaryresult = computed(() => {
      if (!v.salary.total) return "";
      return (
        v.salary.total -
        v.salary.socialSecurityEmployeePart -
        v.salary.personalIncomeTax
      ).toFixed(2);
    });

    function tableChange(pagination) {
      console.log(pagination);
      v.page = pagination.current;
      v.currentPage = pagination.current;
      getlist(userInfo.value.id);
    }
    return {
      ...toRefs(v),
      userInfo,
      salaryresult,
      getsalary,
      tableChange,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "~@/assets/style/mixins";

.content {
  background-color: $color-white;
  padding: 15px;
}
.footer {
  text-align: center;
}
.table {
  margin-top: 20px;
}
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(30px);
  opacity: 0;
}
</style>
